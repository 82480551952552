.Welcome-card {
  --angle: 0deg;
  /* border: 3px solid; */
/* border-image: linear-gradient(var(--angle),rgba(15, 44, 104, 1),rgba(29, 63, 133, 0.846),
rgba(183, 206, 255, 0.6),
rgba(20, 46, 100, 0.5805),
rgba(0, 0, 0, 0)) 1;
  transform: perspective(75em) rotateY(-1deg);
  animation: 4s rotate linear infinite; */
padding: 15px;
/* max-width: 500px; */
width: 40%;
background: var(--bgWhite);
text-align: left;
font-family: var(--fontFamily1);
box-shadow: 10px 10px 54px -6px rgba(0,0,0,1);
position: absolute;
top: 40%;
left:30%;
animation: fadeIn ease 0.2s;
-webkit-animation: fadeIn ease
}

.Welcome-Tour-box-title {
  color: var(--OnboardingCardFontColor);
  font-size: 1.4vw;
  font-weight: var(--OnboardingCardContentFontWeight);
  padding-top: 5%;
  margin: 0px 7%;
  text-align: center;
  margin-bottom: 15px;
  animation: fadeIn ease 0.2s;
  -webkit-animation: fadeIn ease
}

.Welcome-card-container {
  --angle: 0deg;
/* padding: 0% 1.5% 2% 1.5%; */
/* padding: 32px 15px 24px 24px; */
width: calc(32vw + 32svh);
/* max-height: 80svh !important; */
/* height: 70%; */
/* width: 366px; */
background: var(--bgOnboardingCard);
text-align: left;
font-family: var(--fontFamily1);
box-shadow: 10px 10px 54px -6px rgba(0,0,0,1);
position: absolute;
top: 50%;
left:50%;
transform: translate(-50%,-50%);
border-radius: 12px;
animation: fadeIn ease 0.2s;
-webkit-animation: fadeIn ease
}

.welcome-next-btn{
	background: var(--bgOnboardingCardHighlightedButton);
	padding: 2%;
	border-radius: 6px;
	/* width: 20%; */
	/* margin-right: 10%; */
	/* margin-left: 10%; */
	font-size: 1vw;
	cursor: pointer;
}
	.welcome-btn{
	font-size: 1vw;
	color: var(--bgOnboardingCardHighlightedButton);
	cursor: pointer;
}
	.welcome-page{
	width: 50%;
	gap: 3%;
	display: flex;
	align-items: center;
	}
	.welcome-dot-circle-fill{
	fill: var(--bagWelcomeDotCircleFill);
	stroke: var(--bagWelcomeDotCircleStroke);
	}
	.welcome-dot-circle{
	fill: var(--bagWelcomeDotCircle);
	stroke: var(--bagWelcomeDotCircleStroke);
	}
	.wel-title{
	font-size: var(--OnboardingCardFontSize);
	font-family: var(--OnboardingCardFontFamily);
	color: var(--OnboardingCardFontColor);
	font-weight: var(--OnboardingCardFontWeight);
	animation: fadeIn ease 0.2s;
	margin-bottom: 3%;
	}
	.wel-description{
	font-size: var(--OnboardingCardContentFontSize);
	font-family: var(--OnboardingCardContentFontFamily);
	color: var(--OnboardingCardContentFontColor);
	font-weight: var(--OnboardingCardContentFontWeight);
	line-height: var(--OnboardingCardContentLineHeight);
	animation: fadeIn ease 0.3s;
	margin: 0px 3%;
	
	}

	
@keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
	}